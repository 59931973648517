
  // add custom font familly
  @font-face {
    font-family: "Red Hat Display";
    src: url(../public/RedHatDisplay.ttf);
  }

  @font-face {
    font-family: "DM Serif Display" ;
    src: url(../public/DMSerifDisplay.ttf);
  }

  @font-face {
    font-family: Poppins;
    src: url(../public/Poppins-Regular.ttf);
  }

  @font-face {
    font-family: Calibri ;
    src: url(../public/Calibri.ttf);
  }
  @font-face {
    font-family: "Swiss 721 BT";
    src: url(../public/Swiss-721-BT-Font.ttf);
  }

  @font-face {
    font-family: "Swiss 721 LT" ;
    src: url(../public//Sw721Lt.ttf);
  }
  @font-face {
    font-family: "Avenir";
    src: url(../public//AvenirLTStd-Roman.otf);
  }

  @font-face {
    font-family: "Corbel" ;
    src: url(../public/Corbel.ttf);
  }

  @font-face {
    font-family: "Mulish" ;
    src: url(../public/Mulish-Regular.ttf);
  }

  *{
    text-decoration: none;
    margin: 0;
    padding: 0;
  }

  .span-text-to-balck span{
    color: #3b3838 !important;
  }



  @import "./assets/scss/master.scss"