.custom-container {    
    margin: auto;
    padding: 0 15px;
    width: 100%;
}

/*banner*/ 
.css-79elbk {
    overflow: hidden;
}
  
.slick-dots {
    li {
        span {
            transition: all 0.3s;
        }
        &.slick-active {
            span {
                background-color: red !important;
                width: 1rem !important;
            }
        }
    }    
}

@media (min-width: 768px){
    .custom-container {
        max-width: 720px;
    }
}

@media (min-width: 992px){
    .custom-container {
        max-width: 960px;
    }
}

@media (min-width: 1200px){
    .custom-container {
        max-width: 1140px;
    }
}




