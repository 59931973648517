.about-box {
    padding: 60px 0;
    p {
        span {
            font-family: Helvetica;
        }
    }
}
@media(max-width: 1199px) {
    .about-box {
        padding: 50px 0;
    }
}

@media(max-width: 767px) {
    .about-box {
        padding: 35px 0;
        p {
            span {
                font-size: 1rem !important;
                line-height: 26px !important;
            }
        }
    }
}