.footer-common-box {
    padding: 70px 0;
    h3 {
        position: relative;
        min-height: 52px;
        display: flex;
        align-items: center;
        padding-left: 21px;
        margin-bottom: 1.5rem;
        &:before {
            content: '';
            background-color: rgb(255, 174, 0);
            width: 5px;
            height: 52px;
            display: block;
            position: absolute;
            left: 0px;
            top: 0px;
        }
        span {
            font-family: "DM Serif Display" ;
            font-size: 1.55rem !important;            
            letter-spacing: 1.56px;
        }
        strong {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
        br {
            display: none;
        }
    }
    // p {
    //     line-height: 28px;
    //     span {
    //         line-height: 28px;
    //     }
    // }
    ul {
        list-style: none;
        padding: 20px 0;
        li {
            margin-top: 10px;
            position: relative;
            padding-left: 15px;
            &:before {
                content: '';
                width: 5px;
                height: 5px;
                background-color: #000;
                position: absolute;
                left: 0px;
                top: 7px;                
                border-radius: 50%;
            }
            &:first-child {
                margin-top: 0px;
            }
        }
    }
}

.RegisterBtns {
    display: none;
}

.standout-box {    
    ul {
        list-style: none;        
        li {
            margin-top: 10px;
            position: relative;
            padding-left: 15px;
            &:before {
                content: '';
                width: 5px;
                height: 5px;
                background-color: #fff;
                position: absolute;
                left: 0px;
                top: 8px;                
                border-radius: 50%;
            }
            &:first-child {
                margin-top: 0px;
            }
        }
    }
}
.tab-list-box {
    .css-ptiqhd-MuiSvgIcon-root {
        font-size: 1.5rem;
        position: relative;
        top: 6px;
        background: #ccc;
    }
}

#root { 
    padding-top: 68px;    
}

.agenda-left-sticky {
    position: sticky;
    top: 75px;
}

.faculty-sticky {
    position: sticky;
    top: 75px;
}

.faculty-sticky-left {
    position: sticky;
    top: 75px;
    max-height: 600px;
    overflow: auto;
}

@media(max-width: 1299px) {
    .faculty-sticky-left {
        position: static;
        max-height: inherit
    }
}

@media(max-width: 991px) {
    .footer-common-box {
        padding: 50px 0;
        
    }    
}

@media(max-width: 767px) {
    #root { 
        padding-top: 56px;
    }
    .footer-common-box {
        padding: 35px 0;
        p {
            line-height: 26px;
            span {
                line-height: 26px !important;
                font-size: 1rem !important;
            }
        }
        ul {
            li {
                span {
                    line-height: 26px !important;
                    font-size: 1rem !important;
                }
            }
        }        
    }

    .banner-box {
        .column {
            span {
                font-size: 32px !important;
            }
        }
        .banner-content {
            p {
                span {
                    font-size: 1rem !important;
                    line-height: 26px !important;
                }
            }
        }
    }
    .banner-box>div {
        height: 500px !important;
    }

    .event-heading-top {
        display: block !important;    
        .event-heading-right {            
            justify-content: center;
            .imglogoes {
                margin: 10px 0 20px;
            }
        }
    }
}