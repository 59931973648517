.header-box {
    transition: all 0.3s;
    position: fixed !important;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.4);
    .logo-img {
        .mui-image-wrapper {
            transition: all 0.3s;
        }
    }
    .header-inner {

    }
}

@media(max-width: 767px) {
    .header-box {
        .header-inner {
            padding: 8px 7px;       
        }
    }
}