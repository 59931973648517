.footer-box {
    .footer-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 40px 0;
    }
    .footer-links {
        margin-top: 15px;  
        justify-content: center;
        align-items: center;
        .css-1b43hcw-MuiTypography-root:hover {
            color: rgba(255, 255, 255, 0.8);
        }
    }
}

.copyright-box {
    padding: 28px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    p {
        font-size: 1rem;
        color: #fff;
        text-align: center;
    }
}

@media(max-width: 991px) {
    .footer-box { 
        .footer-links {
            margin-top: 10px;            
        }
    }
}

@media(max-width: 767px) {
    .footer-box {
        .footer-inner {
            padding: 25px 0;
        }        
        .css-1vtqtpw-MuiGrid-root {
            width: calc(100% + 10px);
            margin-left: -10px;
        }
        .css-1vtqtpw-MuiGrid-root>.MuiGrid-item {
            padding-top: 10px;
            padding-left: 10px;
        }         
    }
    .copyright-box {
        padding: 20px 0;
    }       
}