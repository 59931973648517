.event-box {
    padding: 70px 0;
    .css-11lq3yg-MuiGrid-root {
        margin-left: -8px;
        margin-right: -8px;
        width: auto;
        justify-content: center;
    }
    .css-1d3bbye { 
        justify-content: center;
    }
    .css-1a85c2i-MuiTypography-root {
        letter-spacing: 1.56px;
    }
    // .mui-image-wrapper {
    //     overflow: hidden;
    //     .mui-image-img {            
    //         transition: 5s all;
    //         &:hover {
    //             -webkit-transform: scale(1.08);
    //             transform: scale(1.08);   
    //         }
    //     }
        
    // }
}


.event-tab-box {
    padding: 70px 0;
}

.gallery-box {
    padding: 60px 0;
    .css-1a85c2i-MuiTypography-root {
        letter-spacing: 1.56px;
        font-family: "DM Serif Display";
    }
    .image-cards {
        overflow: hidden;     
        img {
            transition: 0.1s all;
        }
        &:hover {
            img {                                                    
                transform: scale(1.15);   
            }
        }        
        
    }
}

@media(max-width: 991px) {
    .gallery-box {
        padding: 40px 0;
        .css-3syr29-MuiGrid-root {
            margin-top: 0px;
        }        
    }   
    .event-box {
        padding: 40px 0;
    } 
    .event-tab-box {
        padding: 70px 0 40px;
    }
}

@media(max-width: 767px) {
    .event-box {
        padding: 35px 0;
    }
    .gallery-box {
        padding: 35px 0;        
    }  
    .event-tab-box {
        padding: 70px 0 35px;
    }
}